import React, { useEffect, useState } from 'react';
import './Technology.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Rightarrow from '../Rightarrow/RightArrow'
import app1 from '../../Assets/app (1).png'
import social from '../../Assets/social-media.png'
import g1 from '../../Assets/vector.png'
import seo from '../../Assets/seo.png'
import { Link } from 'react-router-dom';

function Technology() {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const tcard_data = [
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-globe2 t-icon" viewBox="0 0 16 16">
        < path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
      </svg >,
      navigatelink:"/webdev",
      head: 'Website Development',
      text: 'From responsive design to user-friendly interfaces and robust back-end functionality, we harness the latest technologies to deliver websites.'
    },
    {
      icon: <img src={app1} height={40} width={40}></img>,
      navigatelink:"/lg",
      head: 'Leads Generation',
      text: 'Lead generation is a dynamic and multifaceted process that requires a strategic approach to attract, engage, and convert potential customers.'
    },
    {
      icon: <img src={social} height={45} width={45}></img>,
      navigatelink:"/socialmedia",
      head: 'Social Media Marketing',
      text: 'Welcome to Social Connect, your gateway to a new era of Social Media Marketing (SMM) excellence! '
    },
    {
      icon: <img src={g1} height={45} width={45}></img>,
      navigatelink:"/gd",
      head: 'Graphic Design',
      text: 'Graphic design is a dynamic and rewarding field that combines creativity with technical skills.'
    },
    {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-stack t-icon" viewBox="0 0 16 16">
        <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
        <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
      </svg>,
      navigatelink:"/branding",
      head: 'Branding',
      text: `Let us help you craft a brand that not only stands out but leaves a lasting impression in the hearts and minds of your audience.`

    },
    {
      icon: <img src={seo} height={45} width={45}></img>,
      navigatelink:"/seo",
      head: 'Search Engine Optimization',
      text: 'Picture your website not just being visible but thriving at the top of search engine results, effortlessly capturing the attention of your target audience.'
    }
  ]
     

  return (
    <>
      <div className='technology-section'>

        <div className='tcard-container'>
          {tcard_data.map((tcard) => (
            <div className='tcardDiv' key={tcard?.id} data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="50"
              data-aos-offset="0">
              <div className='tcard'>
                <div className='iconDiv pb-3'>{tcard?.icon}</div>
                <h5>{tcard?.head}</h5>
                <p>
                  {tcard?.text}
                </p>
                <Link style={{color:"white"}}  to={tcard?.navigatelink} >  
                <div className='arrowBtn'>
                  <Rightarrow />
                </div>
                </Link>
              </div>
            </div>


          ))}
        </div>
      </div>
    </>
  );
}

export default Technology;