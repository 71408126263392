import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import './Navbar.css';
// import logo from '../../Assets/White-Logo.png'
import logo from '../../Assets/socialconnect_logo.png'

function Navbarfun() {
  return (
      <Navbar expand="lg" className="bg-body-tertiary navbar-light">
        <Navbar.Brand as={Link} to="/"><Logo /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="home-link">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-link">About Us</Nav.Link>
            <Nav.Link as={Link} to="/services" className="nav-link">Services</Nav.Link>
            {/*<NavDropdown title={<Link to="/services" className="dropdown-title-link">Services</Link>} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/DetailService">Detail Service</NavDropdown.Item>
  </NavDropdown>*/}
            <Nav.Link as={Link} to="/portfolio" className="nav-link">Portfolio</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-link">Contact us</Nav.Link>
            <Nav.Link as={Link} to="/faq" className="nav-link">FAQ</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}

export default Navbarfun;

const Logo = () => (
  <Link to="/">
    <img
      src={logo}
      title="logo_digimax"
      alt="logo_digimax"
      loading="lazy"
      // height={80}
      // width={140}
      height={60}  
      width={100}
    />
  </Link>
);
