import React from 'react'
import './Portfolio.css'
import Herosection from '../Herosection/Herosection'
import Footer from '../Footer/Footer'
import Project from '../Project/Project'
import Advantage from '../Advantage-section/Advantage'
import Testimonial from '../Testimonial/Testimonial'
import Navbarfun from '../Navbar/Navbar'


const AboutUs = () => {
  return (
    <>
      <Navbarfun />
      <div className='portfolio_page'>
        <section>
          <Herosection heading="Portfolio" subheading="Revolutionizing Digital Landscapes: Digimax, Your Trailblazing Partner." />
          <section>
            <div className='container-fluid project'>
              <div className='project-content'>
                <h6>
                  FEATURED PROJECT
                </h6>
                <h1> We Make Creative Solutions</h1>
              </div>
              <Project />
            </div>
          </section>
          <section>
            <div className='container-fluid advantage-section'>
              <Advantage />
            </div>
          </section>
          <section>
            <div className='container-fluid testimonial'>
              <Testimonial />
            </div>
          </section>
          <section>
            <div className='container-fluid footer'>
              <Footer />
            </div>
          </section>
        </section >
      </div>
    </>
  )
}

export default AboutUs
