import React from 'react';
import './Project.css'
import pimg1 from '../../Assets/pimg1.png'
import pimg2 from '../../Assets/pimg2.png'
import pimg3 from '../../Assets/pimg3.png'
import pimg4 from '../../Assets/pimg4.png'
import pimg5 from '../../Assets/pimg5.png'
import pimg6 from '../../Assets/pimg6.png'


function Project() {
  const pcard_data = [
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg1
    },
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg2
    },
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg3
    },
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg4
    },
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg5
    },
    {
      head: 'DIGITAL BRANDING',
      text: 'Loka Perfume & Co',
      image: pimg6
    }
  ]

  return (
    <>
      <div className='project-section'>

        <div className='pcard-container'>
          {pcard_data.map((pcard) => (
            <div className='pcard' key={pcard.id}>
              <div className='phead'>
                <h6>{pcard.head}</h6>
                <h4>
                  {pcard.text}
                </h4>
              </div>
              <div className="pcard-img">
                <img src={pcard.image}></img>
              </div>
            </div>


          ))}
        </div>
      </div>
    </>
  );
}

export default Project;